import React from "react"
import { css } from '@emotion/react'
import styled from "@emotion/styled"

import {
  GlutenFreeIcon,
  PaleoIcon,
  VeganIcon,
  NoGMOIcon,
  HighFiberIcon,
  LowCaloriesIcon,
} from "../../svgs/Benefits"

import { benefitIcon, mediaQueries } from "../../styles"

const BenefitsGrid: React.FC = () => {
  return (
    <Grid>
      <GlutenFreeIcon css={glutenFree} />
      <PaleoIcon css={paleo} />
      <VeganIcon css={vegan} />
      <NoGMOIcon css={noGMO} />
      <HighFiberIcon css={highFiber} />
      <LowCaloriesIcon css={lowCalories} />
    </Grid>
  )
}

export default BenefitsGrid

const Grid = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(3, minmax(0, 1fr));

  ${mediaQueries.min768} {
    grid-column-gap: 10%;
    grid-template-columns: repeat(3, minmax(auto, 152px));
    justify-content: flex-end;
  }
`

const glutenFree = css`
  ${benefitIcon}
  grid-row: 1 / span 1;
`

const paleo = css`
  ${benefitIcon}
  grid-column: 3 / span 1;
`

const vegan = css`
  ${benefitIcon}
  grid-column: 2 / span 1;
  grid-row: 2 / span 1;
`

const noGMO = css`
  ${benefitIcon}
  grid-row: 3 / span 1;
`

const highFiber = css`
  ${benefitIcon}
  grid-column: 3 / span 1;
  grid-row: 3 / span 1;
`

const lowCalories = css`
  ${benefitIcon}
  grid-row: 4 / span 1;
  grid-column: 2 / span 1;
`
